<template>
  <div class="row">
    <div class="container">
      <h1>Главная страница{{ url }}</h1>
      <div class="nav">

        <ul>
        <router-link v-slot="{isActive, href, navigate}" v-for="route in this.$router.options.routes" :to="route.path">{{ route.name }}
          <li v-bind:class="{ active: isActive }"><a :href="href" @click="navigate"></a></li>
        </router-link>
        </ul>

      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "IndexPage",
  mounted() {
    this.$router.push('/login')
    //alert(process.env.BAR)
    //console.log('process',process.env)
  },
  //data() { return { url: process.env } }
}
</script>
<style scoped></style>
